import React from "react"
import { GenericHeader } from "@igloonet-web/shared-ui"

import Menu from "./menu"
import Logo from "../icons/logo.svg"
import { ContactForm } from "../components"

const Contact = () => <ContactForm contact="adam" modal />
const IglooLogo = () => <Logo width={150} />

const Header: React.FC = () => {
  return <GenericHeader menu={Menu} logo={IglooLogo} contactForm={Contact} />
}

export default Header
