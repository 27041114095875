import { graphql, useStaticQuery } from "gatsby"

export const useIgloonetFiles = (): Record<string, unknown> => {
  const { allFile } = useStaticQuery(
    graphql`
      query useAllFiles {
        allFile {
          nodes {
            relativePath
            name
            publicURL
          }
        }
      }
    `
  )
  return allFile.nodes
}
