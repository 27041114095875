import { PaddingHalfWrapper } from "@igloonet-web/shared-ui"
import React from "react"

type JobIntroduction = {
  title: string
  children: React.ReactNode
}

const JobIntroduction: React.FC<JobIntroduction> = ({ title, children }) => (
  <PaddingHalfWrapper>
    <h1 className="d-block my-auto pb-5">{title}</h1>
    {children}
  </PaddingHalfWrapper>
)

export default JobIntroduction
