import React from "react"
import styled from "styled-components"
import { Nav, NavItem } from "reactstrap"
import { Link, NavLinkCustom } from "@igloonet-web/shared-ui"

const NavItemCustom = styled(NavItem)`
  display: none;

  @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
  }
`
//Používat pouze v případě, když máme zveřejněná volná místa
const PositionNumber = styled.div`
  position: relative;
  .orange-circle {
    position: absolute;
    top: -10px;
    left: 80px;
    background-color: ${(props) => props.theme.color.primary};
    width: 18px;
    height: 18px;
    border-radius: 50%;

    &:after {
      content: "1";
      position: absolute;
      color: ${(props) => props.theme.color.white};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

const IgloonetMenu: React.FC = () => {
  return (
    <>
      <Nav className="ml-auto align-items-lg-center" navbar>
        <NavItemCustom>
          <NavLinkCustom tag={Link} to="/">
            Úvod
          </NavLinkCustom>
        </NavItemCustom>
        <NavItem>
          <NavLinkCustom tag={Link} to="/marketing">
            Marketing
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="/vyvoj">
            Vývoj
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="/hosting">
            Hosting
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="/reference">
            Reference
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="/tym">
            Tým
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="/jobs">
            <PositionNumber>
              <div className="orange-circle"></div>
            </PositionNumber>
            Volná místa
          </NavLinkCustom>
        </NavItem>
        <NavItem>
          <NavLinkCustom tag={Link} to="https://igloonet.cz/blog/">
            Blog
          </NavLinkCustom>
        </NavItem>
      </Nav>
    </>
  )
}

export default IgloonetMenu
