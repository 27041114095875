import React from "react"
import { GenericContactForm } from "@igloonet-web/shared-ui"
import { useActiveEmployees } from "../hooks/use-active-employees"
import { ProfileImage } from "."

interface ContactFormProps {
  contact: string
  modal?: boolean
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  return (
    <GenericContactForm
      ProfileImage={ProfileImage}
      employees={useActiveEmployees()}
      {...props}
    />
  )
}

export default ContactForm
