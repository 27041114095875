import {
  Content,
  PaddingHalfWrapper,
  TitleH2Center,
} from "@igloonet-web/shared-ui"
import React from "react"

type JobSection = {
  title?: string
  children: React.ReactNode
}

const JobSection: React.FC<JobSection> = ({ title, children }) => (
  <Content>
    <PaddingHalfWrapper>
      <TitleH2Center>{title}</TitleH2Center>
    </PaddingHalfWrapper>
    {children}
  </Content>
)

export default JobSection
