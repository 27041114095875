import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { GenericLayout } from "@igloonet-web/shared-ui"
import { Footer, Header } from "../components"

const Site = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const SiteContent = styled.div`
  flex-grow: 1;
`

type Props = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => (
  <>
    <Helmet>
      <title>Všechny služby pod jedním igloo | igloonet</title>
      <meta
        name="description"
        content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
      />
    </Helmet>
    <GenericLayout>
      <Site>
        <Header />
        <SiteContent>{children}</SiteContent>
        <Footer />
      </Site>
    </GenericLayout>
  </>
)

export default Layout
