import React from "react"
import {
  GenericFooter,
  FooterLink,
  FooterLinks,
  FooterList,
  SocialLinks,
  SocialIconBox,
} from "@igloonet-web/shared-ui"
import LogoNegative from "../icons/logo-negative.svg"

const Footer: React.FC = () => {
  return (
    <GenericFooter>
      <FooterLinks Logo={LogoNegative}>
        <FooterList>
          <FooterLink to="/marketing">Marketing</FooterLink>
          <FooterLink to="/hosting">Hosting</FooterLink>
          <FooterLink to="/vyvoj">Vývoj</FooterLink>
          <FooterLink to="/ecommerce-poradenstvi">
            E-commerce poradenství
          </FooterLink>
          <FooterLink to="/hr-marketing">HR marketing</FooterLink>
        </FooterList>
        <FooterList>
          <FooterLink to="/reference">Reference</FooterLink>
          <FooterLink to="/tym">Tým</FooterLink>
          <FooterLink to="https://jobs.igloonet.cz">Volná místa</FooterLink>
        </FooterList>
        <FooterList>
          <FooterLink to="/kontakt">Kontakt</FooterLink>
          <FooterLink to="/ochrana-osobnich-udaju">
            Zásady ochrany osobních údajů
          </FooterLink>
        </FooterList>
      </FooterLinks>

      <SocialLinks>
        <SocialIconBox
          to="https://igloonet.cz/blog/"
          name="Blog"
          text="Čtěte od nás"
        />

        <SocialIconBox
          to="https://www.facebook.com/igloonet"
          name="Facebook"
          text="Zasloužíme si palec nahoru"
        />

        <SocialIconBox
          to="https://www.instagram.com/igloonet/"
          name="Instagram"
          text="Žijte stories. #nejsmezledu"
        />

        <SocialIconBox
          to="https://www.linkedin.com/company/igloonet/"
          name="LinkedIn"
          text="Skills, které potřebujete"
        />
      </SocialLinks>
    </GenericFooter>
  )
}

export default Footer
