import React from "react"
import styled from "styled-components"

const JobDetailMainWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  z-index: 1050;
`

const JobDetailWrapper = styled.div`
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  max-width: 944px;
  margin: 1.75rem auto;
  z-index: 2000;
  background: ${(props) => props.theme.color.white};
`

const JobDetailWindow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`

const JobWrapper: React.FC = ({ children }) => (
  <>
    <JobDetailWindow />
    <JobDetailMainWrapper>
      <JobDetailWrapper>{children}</JobDetailWrapper>
    </JobDetailMainWrapper>
  </>
)

export default JobWrapper
