import { graphql, useStaticQuery } from "gatsby"

export const useActiveEmployees = (): Record<string, unknown> => {
  const { allEmployeesYaml } = useStaticQuery(
    graphql`
      query useActiveEmployeesQuery {
        allEmployeesYaml(filter: { isEnded: { ne: true } }) {
          nodes {
            name
            surname
            login
            teams
            division
            telephone
            mail
            started(formatString: "D. M. YYYY")
            social {
              facebook
              linkedin
              twitter
            }
            contact_form {
              heading
              subheading
              box1_desc
              box1_link
              box1_name
              box1_disable
              box2_desc
              box2_link
              box2_name
              box2_disable
              box3_desc
              box3_link
              box3_name
              box3_disable
              button_title
              button_subtitle
            }
          }
        }
      }
    `
  )
  return allEmployeesYaml.nodes
}
