import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Row } from "reactstrap"
import { PaddingWrapper, TitleH2Center } from "@igloonet-web/shared-ui"

type JobPositionListProps = {
  data: Array<JobPositionProps>
}

type JobPositionProps = {
  title: string
  text: string
  image: string
  href: string
}

const JobPositionWrapper = styled(Link)`
  height: 100%;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  border: 2px solid ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.blue};
  transition: all 0.2s ease-in-out;
  border-radius: 14px;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const JobPositionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const JobPositionText = styled.div`
  font-size: 12px;
`

const JobPosition: React.FC<JobPositionProps> = (props) => (
  <JobPositionWrapper to={props.href}>
    <img
      src={props.image}
      alt=""
      loading="lazy"
      height="48"
      width="48"
      className="mr-3"
    />
    <div>
      <JobPositionTitle>{props.title}</JobPositionTitle>
      <JobPositionText>{props.text}</JobPositionText>
    </div>
  </JobPositionWrapper>
)

const JobPositionList: React.FC<JobPositionListProps> = (props) => (
  <Container id="jobs">
    <PaddingWrapper>
      <TitleH2Center>Právě hledáme</TitleH2Center>
      <Row>
        {props.data.map((item, index) => {
          return (
            <div key={index} className="mb-4 w-lg-50 mx-auto">
              <JobPosition
                title={item.title}
                text={item.text}
                image={item.image}
                href={item.href}
              />
            </div>
          )
        })}
      </Row>
    </PaddingWrapper>
  </Container>
)

export default JobPositionList
