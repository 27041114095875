import React from "react"
import styled, { keyframes } from "styled-components"
import VideoBannerMP4 from "../../videos/video-banner.mp4"
import VideoBannerWEBM from "../../videos/video-banner.webm"
import VideoPoster from "../../images/video-poster.jpg"
import Tucnak from "../../images/tucnak/tucnak.svg"

import { Container } from "reactstrap"

import ScrollMouseImage from "../../images/scroll-mouse.png"
import ScrollArrowImage from "../../images/scroll-arrow.png"

const ScrollAnimation = keyframes`
  0% {
    top: 0;
    opacity: 0;
  }
  10% {
    top: 0;
    opacity: 1;
  }
  25% {
    top: 12px;
    opacity: 1;
  }
  50% {
    top: 12px;
    opacity: 1;
  }
  100% {
    top: 39px;
    opacity: 0;
  }
`

const ScrollMouse = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 22px;
    height: 88px;
    margin: 0 0 0 -11px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: url(${ScrollMouseImage}) no-repeat 0 0;
    background-size: 22px 88px;
  }
`

const ScrollArrow = styled.div`
  width: 22px;
  height: 15px;
  margin: 49px 0 0 0;
  position: relative;
  display: inline-block;
  background: url(${ScrollArrowImage}) no-repeat 0 0;
  background-size: 22px 15px;
  animation-name: ${ScrollAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`

const Scroll = () => (
  <ScrollMouse>
    <ScrollArrow />
  </ScrollMouse>
)

const Banner = styled.div`
  position: relative;
  padding: 30px 0;
  max-height: calc(100vh - 59px);
  background: ${(props) => props.theme.color.primary};
  overflow: hidden;

  &:before {
    @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
      content: "";
      background: url(${Tucnak}) no-repeat 0 0;
      background-size: contain;
      width: 250px;
      height: 407px;
      display: block;
      position: absolute;
      bottom: -150px;
      right: 50px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 0;
  }
`

const BannerVideoWrapper = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    display: block;
  }

  &,
  video {
    overflow: hidden;
    max-height: calc(100vh - 59px);
    object-fit: cover;
  }
`

const BannerTextWrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const BannerTitle = styled.h1`
  color: ${(props) => props.theme.color.white};
  font-size: 28px;
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    font-size: 40px;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    font-size: 48px;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    font-size: 56px;
  }
`

const BannerText = styled.div`
  color: ${(props) => props.theme.color.white};
  font-size: 16px;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    font-size: 20px;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    font-size: 28px;
  }
`

const BannerJobs: React.FC = () => (
  <Banner>
    <BannerVideoWrapper className="">
      <video
        className="lazy"
        autoPlay
        muted
        loop
        playsInline
        width="100%"
        height="1080"
        poster={VideoPoster}
      >
        <source src={VideoBannerWEBM} type="video/webm" />
        <source src={VideoBannerMP4} type="video/mp4" />
      </video>
    </BannerVideoWrapper>

    <BannerTextWrapper>
      <Container>
        <BannerTitle>
          Každý strávíme třetinu života v práci. Vybírej chytře, s kým ji
          prožiješ
        </BannerTitle>
        <BannerText>
          Chceš pracovat v kolektivu, kde bude tvé slovo slyšet?
          <br />
          Staň se tučňákem a pracuj v igloo. #nejsmezledu
        </BannerText>
      </Container>
    </BannerTextWrapper>

    <Scroll />
  </Banner>
)

export default BannerJobs
