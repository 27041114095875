import React from "react"
import { Container } from "reactstrap"
import { PaddingWrapper, Button, ButtonStyle } from "@igloonet-web/shared-ui"

const JobsContact: React.FC = () => (
  <PaddingWrapper>
    <Container className="text-center">
      <h4>Chybí ti zde tvá vysněná pozice?</h4>
      <p>
        Jestli něco umíš a věříš si, navrhni si vlastní!{" "}
        <strong>Nesnášíme mrhání talentem</strong>.
      </p>
      <Button to="#formular" style={ButtonStyle.Primary}>
        Napiš nám
      </Button>
    </Container>
  </PaddingWrapper>
)

export default JobsContact
